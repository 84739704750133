import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import Routing from './router/routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/preee-theme.scss?v=1.3.0";
import './assets/demo/demo.css';

function App() {

    return (
        <div className="App">
                <Router>
                    <Switch>
                        <Routing />
                    </Switch>
                </Router>
        </div>
    );
}

export default App;
