import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import classnames from 'classnames';
import { Collapse, NavbarBrand, Navbar, NavItem, Nav, Container, Button } from "reactstrap";
import styles from "./styles.module.scss";

function Layout({ children }) {
    const redirectToPreee = () => {
        window.open('https://preee.me/download-app', '_blank');
    };

    return (
        <div id="container">

            <Navbar expand="lg">
                <div className={styles.headerCompany}><a className={styles.alink} href="/"><p style={{ fontWeight: "700" }}>Preee:</p> Daily Task Focus</a></div>
                <div className={styles.logout}>
                    <Button className={styles.months} onClick={redirectToPreee}>
                        Download Preee
                    </Button>
                </div>

            </Navbar>
            <div className="main-content">
                <div className="public-content">
                    {children}
                </div>
            </div>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.object.isRequired,
};

export default Layout;
