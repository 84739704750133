import Home from "../views/home";
import TermsOfService from "../views/termsOfService";
import PrivacyPolicy from "../views/privacyPolicy";
import FAQ from "../views/faq";
import Contacts from "../views/contacts";

const routes = [
    {
        id: 1,
        path: '/',
        component: Home,
        isPublic: true,
    },
    {
        id: 2,
        path: '/terms-Of-service',
        component: TermsOfService,
        isPublic: true,
    },
    {
        id: 3,
        path: '/privacy-policy',
        component: PrivacyPolicy,
        isPublic: true,
    },
    {
        id: 4,
        path: '/faq',
        component: FAQ,
        isPublic: true,
    },
    {
        id: 5,
        path: '/contacts',
        component: Contacts,
        isPublic: true,
    },
    
    
]
export default routes;