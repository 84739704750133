import React from "react";
import { Route, } from 'react-router-dom';
import routes from './routes';
import LandingLayout from "../components/landingLayout";

const PrivateRoutes = () => {
    const publicRoutes = routes.filter((routes) => !routes.isPublic);

    return publicRoutes.map((route, index) => {
        const RouteVal = route.component;

        return (
            <Route
                key={index}
                path={route.path}
                exact
                render={(props) => (
                    <LandingLayout>
                        <RouteVal {...props} />
                    </LandingLayout>
                )}
            >
            </Route>
        )
    })
}

export default PrivateRoutes;