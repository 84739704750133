// WelcomePage.js
import React from "react";
import { Card, CardHeader, CardBody, Form, Container, Col, Row, Button } from "reactstrap";
import styles from "../home/styles.module.scss";

function Contacts() {
    return (
        <div className={styles.termsOfService} >
            {/*page 2*/}
            <Row className={styles.termsMainContent}>
                <p className={styles.termsText}>
                    CONTACTS
                </p>
                <br />

                <p className={styles.ptag}>
                    For all store inquiries, contact <a className={styles.link} href="mailto:{{%20shop_email%20}}"><u>contact@support.preee.app</u></a>
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Row>
        </div>
    );
}

export default Contacts;
