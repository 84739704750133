// WelcomePage.js
import React from "react";
import { Card, CardHeader, CardBody, Form, Container, Col, Row, Button } from "reactstrap";
import styles from "../home/styles.module.scss";

function FAQ() {
    return (
        <div className={styles.termsOfService} >
            {/*page 2*/}
            <Row className={styles.termsMainContent}>
                <p className={styles.termsText}>
                    RETURNS & FAQ
                </p>
                <br />

                <p className={styles.ptag}>
                    We want to make sure you love our products, and quality is guaranteed. If there is a print error or visible quality issue,
                    we'll replace or refund it. For any quality issues, be sure to provide clear photos of the products on a flat, well-lit surface
                    and include this in your email to us a <a className={styles.link} href="mailto:{{%20shop_email%20}}"><u>contact@support.preee.app</u></a>. This quick and simple
                    step will help us provide a speedy resolution.
                </p>
                <br />
                <p className={styles.ptag}>
                    Because products are made to order, we do not accept general returns or sizing-related returns.
                </p>
                <br />
                <p className={styles.ptag}>
                    Please read below for more details:
                </p>

                <br />
                <br />

                <p className={styles.termsText}>
                    CANCELLATIONS
                </p>
                <p className={styles.smallTitle}>
                    PRODUCT ORDERS CANCELLATION POLICY
                </p>

                <p className={styles.ptag}>
                    All of our products are made to order, especially for you. If you wish to cancel or amend your order, please use the link provided in your confirmation email.
                    You can edit your order at any time before it goes to production.
                </p>

                <br />

                <p className={styles.ptag}>
                    Once your order has gone to production, you may be eligible for a replacement/resolution, depending on the situation. After you've received your order, you have 30 days to address any quality issues.
                </p>

                <br />
                <br />

                <p className={styles.termsText}>
                    DONATIONS AND TIPS CANCELLATION POLICY
                </p>
                <p className={styles.ptag}>
                    Please note that donations and tips are non-cancellable and non-refundable. Once you have completed your checkout for donations or tips, we cannot cancel or refund it.
                </p>

                <br />
                <br />

                <p className={styles.termsText}>
                    MEMBERSHIP SUBSCRIPTIONS CANCELLATION POLICY
                </p>
                <p className={styles.ptag}>
                    You can cancel your membership at any time via your profile settings page. Please note that cancellations will take effect after your current membership subscription period ends.
                    There is no refund or cancellation for the current membership subscription period.
                </p>

                <br />
                <br />

                <div className={styles.termsText}>
                    DAMAGED/QUALITY ISSUES
                </div>
                <p className={styles.ptag}>
                    For the fastest resolution, please include a photograph demonstrating the quality issue of the print or the damaged area of the item, along with your order number.
                    The most optimal pictures are on a flat surface, with the tag and error clearly displayed.
                </p>
                <br />
                <p className={styles.ptag}>
                    Please email us with these details at <a className={styles.link} href="mailto:{{%20shop_email%20}}"><u>contact@support.preee.app</u></a>
                </p>

                <br />
                <br />

                <p className={styles.termsText}>
                    REFUNDS POLICIES
                </p>
                <p className={styles.smallTitle}>
                    PRODUCT ORDERS CANCELLATION POLICY
                </p>
                <p className={styles.ptag}>
                    Because products are made to order, we cannot issue refunds, returns, or exchanges for orders except for those with quality issues. Orders are non-refundable unless they
                    meet these requirements and you provide support with a photograph demonstrating the quality issue.
                    PayPal: Any refunds processed will show back up in your PayPal account balance within 24 business hours.
                </p>
                <br />
                <p className={styles.ptag}>
                    Credit Card: Any refunds processed via your credit/debit card will show back up in your bank account within 7 to 10 business days, depending on your bank.
                </p>


                <br />
                <br />

                <p className={styles.termsText}>
                    MEMBERSHIP SUBSCRIPTIONS CANCELLATION POLICY
                </p>
                <p className={styles.ptag}>
                    Membership subscriptions are non-refundable. You can always cancel your membership at any time, and this cancellation will take effect at the end of your membership subscription period.
                    There is no refund for the current membership subscription period that is ongoing.
                </p>

                <br />
                <br />

                <p className={styles.termsText}>
                    ACCEPTED PAYMENT METHODS
                </p>
                <p className={styles.ptag}>
                    We accept payments via credit/debit cards, PayPal, Google Pay, Apple Pay, and depending on your location, we also accept Klarna/AfterPay and local payment methods.
                </p>

                <br />
                <br />

                <p className={styles.termsText}>
                    INTERNATIONAL ORDERS
                </p>
                <p className={styles.ptag}>
                    WILL I HAVE TO PAY ANY ADDITIONAL TAXES ON MY ORDER IF I'M LOCATED OUTSIDE OF THE US?
                </p>
                <br />
                <p className={styles.ptag}>

                    International orders may be subject to import taxes, duties, and other customs charges. The charges vary by country, and at this time,
                    we are unable to calculate them in advance. For more information regarding your country's customs policies, please contact your local customs office.
                    If such a fee indeed gets imposed on your package, you are responsible for its payment.
                </p>



                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Row>
        </div>
    );
}

export default FAQ;
