import React from "react";
import styles from "./styles.module.scss";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, CardBody, Form, Container, Col, Row, Button } from "reactstrap";

function LandingLayout({ children }) {
    const redirectToPreee = () => {
        window.open('https://preee.me/download-app', '_blank');
    };

    return (
        <div className="login-page" style={{ overflowX: "hidden" }}>
            <Row className={styles.mainContent}>
                <p className={styles.pocketText}>
                    Increase Your Productivity
                </p>
                <br />
                <Button className={styles.freeButten} onClick={redirectToPreee}>
                  Download Preee
                </Button>
                <p className={styles.subscribers}>
                    *  New subscribers only. $4.99/month or $49.99/year after free trial. Plan automatically renews until cancelled.
                </p>
                <p className={styles.footerValue} >
                    &copy; 2024 Goated Ones LLC
                    <a className={styles.link1} href="/terms-Of-service" >Terms of Service</a>

                    <a className={styles.link} href="/privacy-policy" >Privacy Policy </a>

                    <a className={styles.link} href="/faq">Returns & FAQ </a>

                    <a className={styles.link} href="/contacts" >Contacts</a>

                </p>
            </Row>
        </div>
    );

}

export default withRouter(LandingLayout);
