import React, { useState } from "react";
import { Card, CardHeader, CardBody, Form, Container, Col, Row, Button } from "reactstrap";
import styles from "./styles.module.scss";
import AddTask from "../../images/AddTask.mp4"
import Chat from "../../images/Chat.mp4"
import Calendar from "../../images/Calendar.mp4"
import Profile from "../../images/Profile.mp4"
import arrow from "../../images/arrow.png"
import image1 from "../../images/image 1.png"
import image2 from "../../images/image 2.png"
import image3 from "../../images/image 3.png"
import image4 from "../../images/image 4.png"

function Home(props) {

    const [currentVideo, setCurrentVideo] = useState(AddTask);
    const [arrowPosition, setArrowPosition] = useState(200);
    const [textColor, setTextColor] = useState("1");

    const handlePtagClick = (videoSource, arrowTopPosition, textColor) => {
        setCurrentVideo(videoSource);
        setArrowPosition(arrowTopPosition);
        setTextColor(textColor);
    };

    const redirectToPreee = () => {
        window.open('https://preee.me/download-app', '_blank');
    };

    return (
        <div className="wrapper wrapper-full-page">
            <div className="login-page">
                {/*page 1*/}
                <Row >
                    <Col lg="5" md="5" sm="12" xs="12" className={styles.col}>
                        <div>
                            <p className={styles.preee}>Preee<br /> Daily Task App</p>
                            <p className={styles.preeeText}>Streamline life by honing in on your top three tasks, supported by timely notifications and friends' motivational feedback</p>
                        </div>
                    </Col>
                    <Col lg="3" md="3" sm="12" xs="12" className={styles.col2}>
                        <video autoPlay loop muted src={currentVideo} className={styles.phoneimage}>
                        </video>
                    </Col>
                    <Col lg="2" md="2" sm="12" xs="12" className={styles.col3}>
                        <img className={styles.arrow} src={arrow} alt="Arrow" style={{ bottom: `${arrowPosition}px` }} />
                    </Col>
                    <Col lg="2" md="2" sm="12" xs="12" className={styles.col4}>
                        <div className={styles.col4text}>

                            <div className={styles.ptag1} style={{ color: textColor === "1" ? "#191919" : "#19191980" }} onClick={() => handlePtagClick(AddTask, 200,"1")}>
                                <p >FOCUS ON THE THREE MAIN <br />TASKS OF THE DAY</p>
                            </div>

                            <div className={styles.ptag2} style={{ color: textColor === "2" ? "#191919" : "#19191980" }}  onClick={() => handlePtagClick(Calendar, 106,"2")}>
                                <p >TRACK YOUR DAILY <br />PROGRESS</p>
                            </div>

                            <div className={styles.ptag3} style={{ color: textColor === "3" ? "#191919" : "#19191980" }}  onClick={() => handlePtagClick(Chat, 40, "3")}>
                                <p>KEEP UP WITH YOUR<br />BREDRENS' ACHIEVEMENTS</p>
                            </div>

                            <div className={styles.ptag4} style={{ color: textColor === "4" ? "#191919" : "#19191980" }} onClick={() => handlePtagClick(Profile, -35, "4")}>
                                <p> CUSTOMIZE NOTIFICATIONS </p>
                            </div>
                                
                        </div>
                    </Col>
                </Row>
                {/*page 2*/}
                <Row className={styles.mainContent}>
                    <p className={styles.pocketText}>
                        From <span className={styles.jamaica}>Jamaica</span> to your pocket
                    </p>
                    <br />
                    <p className={styles.JamaicaText}>
                        In Jamaica, Preee is synonymous with agenda, often expressed in the phrase "what de pree?"
                        signifying one's plans or intentions. Our app, Preee, aims to encapsulate this spirit by enhancing daily task
                        management and fostering communal support in achieving personal goals. It's about transforming the traditional
                        Jamaican diligence into a digital tool for focused productivity and shared success.
                    </p>
                    <Button className={styles.freeButten} onClick={redirectToPreee}>
                        Download Preee
                    </Button>
                </Row>

                {/*page 3*/}
                <Row className={styles.row1}>
                    <Col lg={6} md={6} sm={12} xs={12} >
                        <img src={image1} alt="Your Alt Text" className={styles.col1} />
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={12} className={styles.friend}>
                        <div>
                            <p className={styles.headingText}> <span className={styles.heading}> One. Two. Preee.</span>
                                <br /><br />Focus on the three main tasks of the day and increase your productivity.</p>
                        </div>
                    </Col>
                </Row>

                <Row className={styles.row2}>
                    <Col lg={6} md={6} sm={12} xs={12} className={styles.onePreee}>
                        <div>

                            <p className={styles.headingText}> <span className={styles.heading}>and... Done!</span>
                                <br /><br />Track your progress every day using the convenient navigation and calendar features of the app.</p>
                        </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={12} >
                        <img src={image2} alt="Your Alt Text" className={styles.col1} />
                    </Col>
                </Row>

                <Row className={styles.row2}>
                    <Col lg={6} md={6} sm={12} xs={12} >
                        <img src={image3} alt="Your Alt Text" className={styles.col1} />
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={12} className={styles.friend}>
                        <div>

                            <p className={styles.headingText}> <span className={styles.heading}>Friendship Above All</span>
                                <br /><br />Invite your bredren, track each other's successes, and help each other complete daily tasks.</p>
                        </div>
                    </Col>
                </Row>

                <Row className={styles.row2}>
                    <Col lg={6} md={6} sm={12} xs={12} className={styles.onePreee}>
                        <div>

                            <p className={styles.headingText}> <span className={styles.heading}>All Under Control</span>
                                <br /><br />Convenient reminders will not allow you to miss a task, and flexible notification settings will allow you to control everything down to the smallest detail.</p>
                        </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={12} >
                        <img src={image4} alt="Your Alt Text" className={styles.col1} />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Home;
